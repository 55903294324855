import { pickMeta } from 'api/GraphQL/util';
import {
  makeProductForm,
  ProductForm,
  productFormToSaleorInput,
} from 'container/ProductDetailContainer/utils';
import {
  useGetHashtagsForEntity,
  useLinkHashtagsToProducts,
} from 'features/hashtags/queries';
import { productListKeys } from 'features/product-list';
import { FormikHelpers } from 'formik';
import { ApiError, HashtagEntityDTO } from 'generated';
import {
  LanguageCodeEnum,
  Product,
  useProductTranslateMutation,
  useCreateProductBulkMutation,
  useProductBulkTranslateMutation,
  useProductCheckQuery,
  useProductVariantBulkUpdateMutation,
  useUpdateProductMutation,
  useProductVariantChannelListingUpdateMutation,
  useProductVariantSetDefaultMutation,
  useGetSaleorProductsQuery,
  useProductTypesQuery,
} from 'generated/gql/gql';
import useAppStatus from 'hooks/useAppStatus';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useReduxDispatch, useReduxSelector } from 'redux/hooks';
import { logOut, selectVendorAgent } from 'redux/slices/auth/authSlice';
import {
  selectLanguageCodeEnum,
  selectSupportedLanguages,
} from 'redux/slices/i18n/i18nSlice';
import notAuthenticated from 'utils/not-authenticated';
import { pickJbeProductId } from './util';
import {
  selectSaleorChannel,
  selectSaleorChannelId,
  selectSaleorWarehouseId,
} from 'redux/slices/global/globalSlice';
import useProductTypes from 'hooks/useProductTypes';
export const useProductForm = (productId?: string) => {
  const { t } = useTranslation(['translation', 'product', 'edit']);
  const dispatch = useReduxDispatch();
  const navigate = useNavigate();
  const saleorChannel = useReduxSelector(selectSaleorChannel);
  const saleorChannelId = useReduxSelector(selectSaleorChannelId);
  const saleorWarehouseId = useReduxSelector(selectSaleorWarehouseId);
  const productTypes = useProductTypes();
  const languageCode = useReduxSelector(selectLanguageCodeEnum);
  const vendor = useReduxSelector(selectVendorAgent);
  // const { data: productTypesData } = useProductTypesQuery();
  const supportedLanguages = useReduxSelector(selectSupportedLanguages);
  const { mutate: batchLinkHashtagsToEntities } = useLinkHashtagsToProducts();
  const {
    mutate: createProductBulk,
    isLoading: creatingProduct,
    status: createStatus,
    error: createError,
  } = useCreateProductBulkMutation();
  const { setAppStatus } = useAppStatus();
  const {
    data: { product } = {} as { product: Product },
    refetch: refetchProduct,
  } = useProductCheckQuery(
    {
      id: productId ?? '',
      languageCode,
      saleorChannel: saleorChannel!,
    },
    {
      queryKey: productListKeys.getProducts({
        ids: productId ? [productId] : [],
      }),
      enabled: !!productId && !!languageCode,
      retry: false,
      onError: (error: ApiError) => {
        if (notAuthenticated(error)) {
          dispatch(logOut());
        } else {
          setAppStatus(error?.message ?? t('product:add.error.load'), 'error');
        }
      },
    },
  );
  const { data: hashtags } = useGetHashtagsForEntity(
    pickJbeProductId(product as Product)!,
    HashtagEntityDTO.entityType.PRODUCT,
  );
  const { mutate: setDefaultVariant } = useProductVariantSetDefaultMutation();
  const {
    mutate: productTranslate,
    isLoading: addingTranslation,
    error: translationError,
  } = useProductTranslateMutation();

  const {
    mutate: productVariantBulkUpdate,
    isLoading: updatingProductVariant,
  } = useProductVariantBulkUpdateMutation();
  const {
    mutate: bulkTranslate,
    isLoading: translating,
    error: bulkTranslationError,
  } = useProductBulkTranslateMutation();
  const {
    mutate: _updateProduct,
    isLoading: updatingProduct,
    status: updateStatus,
    error: updateError,
  } = useUpdateProductMutation();
  const creating = creatingProduct || translating;
  const updating = updatingProduct || updatingProductVariant;
  const errors = [
    translationError,
    bulkTranslationError,
    updateError,
    createError,
  ].filter(Boolean);
  const createProduct = async (
    product: ProductForm,
    actions: FormikHelpers<ProductForm>,
  ) => {
    try {
      const { bulkTranslateInput, bulkCreationInput } =
        productFormToSaleorInput(product);

      createProductBulk(
        {
          products: [bulkCreationInput],
        },
        {
          onSuccess: async ({ productBulkCreate }) => {
            const hasErrors =
              productBulkCreate?.errors?.length ||
              productBulkCreate?.results?.some(
                (result) => (result.errors?.length ?? 0) > 0,
              );
            if (hasErrors) {
              console.log('createProductBulk', {
                productBulkCreate,
                hasErrors,
              });
              setAppStatus(
                productBulkCreate?.errors?.[0]?.message ??
                  productBulkCreate?.results?.[0]?.errors?.[0]?.message ??
                  t('product:add.error.save'),
                'error',
              );
              return;
            }
            const _product = productBulkCreate?.results?.[0]?.product;
            const id = _product?.id;
            if (id) {
              if (_product?.variants?.length) {
                setDefaultVariant({
                  productId: id,
                  variantId: _product.variants[0].id,
                });
              }
              bulkTranslate(
                {
                  translations: bulkTranslateInput(_product as Product),
                },
                {
                  onSuccess: async () => {
                    setAppStatus(
                      t('product:add.success', {
                        product: _product?.externalReference,
                      }),
                      'success',
                      true,
                    );
                    navigate(`/products/${id}`);
                  },
                },
              );
            }
          },
          onError: (error) => {
            if (notAuthenticated(error as ApiError)) {
              dispatch(logOut());
            } else if (
              (error as ApiError)?.body?.error === 'DuplicatedEntryError'
            ) {
              setAppStatus(t('product:add.error.duplicateVendorId'), 'error');
              actions.setFieldError(
                'externalReference',
                t('product:add.error.duplicateVendorId'),
              );
            } else {
              setAppStatus(t('product:add.error.save'), 'error');
            }
          },
        },
      );
    } catch (error) {
      setAppStatus(t('product:add.error.save'), 'error');
    }
  };

  const updateProduct = async (
    values: ProductForm,
    actions: FormikHelpers<ProductForm>,
  ) => {
    if (productId) {
      const { updateInput, translations, bulkProductVariantUpdateInput } =
        productFormToSaleorInput(values);
      await Promise.all([
        await _updateProduct(
          {
            id: productId,
            input: updateInput,
          },
          {
            onSuccess: async ({ productUpdate }) => {
              const product = productUpdate?.product as Product;
              const id = product?.id;
              if (id) {
                await Promise.all([
                  ...Object.entries(translations).map(
                    async ([locale, translation]) =>
                      await productTranslate({
                        id,
                        input: translation,
                        languageCode: locale as LanguageCodeEnum,
                      }),
                  ),
                  // await productVariantChannelListingUpdate({
                  //   id: product?.defaultVariant?.id,
                  //   input: (product?.defaultVariant?.channelListings ?? []).map(
                  //     (listing, index) => ({
                  //       channelId: listing?.channel?.id,
                  //       price: values.localisations[index].price,
                  //     }),
                  //   ),
                  // }),
                  console.log('bulkProductVariantUpdateInput', {
                    product,
                    input: bulkProductVariantUpdateInput(product),
                    form: values.localisations,
                  }),
                  await productVariantBulkUpdate({
                    product: id,
                    variants: bulkProductVariantUpdateInput(product),
                  }),
                ]).then(() => {
                  setAppStatus(
                    t('product:add.success', {
                      product: product?.externalReference,
                    }),
                    'success',
                    true,
                  );
                });
              }
            },
            onError: (error) => {
              if (notAuthenticated(error as ApiError)) {
                dispatch(logOut());
              } else if (
                (error as ApiError)?.body?.error === 'DuplicatedEntryError'
              ) {
                setAppStatus(t('product:add.error.duplicateVendorId'), 'error');
                actions.setFieldError(
                  'externalReference',
                  t('product:add.error.duplicateVendorId'),
                );
              } else {
                setAppStatus(t('product:add.error.save'), 'error');
              }
            },
          },
        ),
        // await updateJbeHashtags(values, product as Product),
      ]);
    }
  };
  useEffect(() => {
    if (errors.length) {
      errors.map((error) => {
        if (error instanceof ApiError) {
          setAppStatus(error.body.message, 'error');
        } else {
          setAppStatus(t('product:add.error.save'), 'error');
        }
      });
    }
  }, [errors, setAppStatus]);
  const initial = useMemo(
    () =>
      makeProductForm({
        product: product as Product,
        hashtags,
        supportedLanguages,
        saleorChannelId,
        saleorWarehouseId,
        productTypes: productTypes ?? [],
      }),
    [product, hashtags, supportedLanguages, saleorChannelId, saleorWarehouseId],
  );
  return {
    product: product as Product,
    updateProduct,
    createProduct,
    initialForm: initial,
    updating,
    addingTranslation,
    creating,
    isLoading: updating || addingTranslation || creating,
    createStatus,
    updateStatus,
  };
};

export const useSaleorProduct = (id?: string, language?: LanguageCodeEnum) => {
  const defaultLanguage = useReduxSelector(selectLanguageCodeEnum);
  const dispatch = useReduxDispatch();
  const { t } = useTranslation();
  const saleorChannel = useReduxSelector(selectSaleorChannel);

  const { setAppStatus } = useAppStatus();
  const { data: { product } = {}, ...params } = useProductCheckQuery(
    {
      id: id ?? '',
      languageCode: language ?? defaultLanguage,
      saleorChannel: saleorChannel!,
    },
    {
      queryKey: productListKeys.getProducts({
        ids: id ? [id] : [],
      }),
      enabled: !!id && !!language,
      onError: (error: ApiError) => {
        if (notAuthenticated(error)) {
          dispatch(logOut());
        } else {
          setAppStatus(error?.message ?? t('product:add.error.load'), 'error');
        }
      },
    },
  );
  return {
    ...(product as Product),
    jbeProductId: pickJbeProductId(product as Product),
    params,
  };
};

export const useSaleorProducts = (
  ids: string[],
  language?: LanguageCodeEnum,
) => {
  const defaultLanguage = useReduxSelector(selectLanguageCodeEnum);
  const dispatch = useReduxDispatch();
  const { t } = useTranslation();
  const saleorChannel = useReduxSelector(selectSaleorChannel);

  const { setAppStatus } = useAppStatus();
  const { data: { products } = {}, ...params } = useGetSaleorProductsQuery(
    {
      ids,
      languageCode: language ?? defaultLanguage,
      saleorChannel: saleorChannel!,
    },
    {
      queryKey: productListKeys.getProducts({
        ids,
      }),
      enabled: !!ids?.length && !!language,
      onError: (error: ApiError) => {
        if (notAuthenticated(error)) {
          dispatch(logOut());
        } else {
          setAppStatus(error?.message ?? t('product:add.error.load'), 'error');
        }
      },
      retry: false,
    },
  );
  return {
    products: products?.edges?.map(({ node }) => node) as Product[],
    record: products?.edges
      ?.map(({ node }) => node)
      .reduce(
        (acc, product) => ({ ...acc, [product.id]: product as Product }),
        {} as Record<string, Product>,
      ),
    ...params,
  };
};
