import classNames from 'classnames';
import CategoryBranch from 'components/CategoryBranch/CategoryBranch';
import Chip from 'components/Chip/Chip';
import { FlexBox, Spacer } from 'components/Layout';
import { BREAKPOINTS } from 'global-constants';
import { useMediaQuery } from 'hooks/useMediaquery';
import { useTranslation } from 'react-i18next';
import Color, { ColorName } from 'types/Color';
import LabelText from '../LabelText/LabelText';
import LabeledInfoText from '../LabeledInfoText/LabeledInfoText';
import LocalisationTabs from '../LocalisationTabs/LocalisationTabs';
import TabPane from '../LocalisationTabs/TabPane';
import ProductVideoList from './ProductVideoList';
import './product-info.scss';
import { Category, Product } from 'generated/gql/gql';
import { useEffect, useMemo } from 'react';
import { useGetProductVideos } from 'features/product-list/queries';
import { useReduxSelector } from 'redux/hooks';
import { selectLanguageCode, selectSupportedLanguages } from 'redux/slices/i18n/i18nSlice';
import { pickHashtags, pickProductPrice, pickProductShopUrl, pickProductStock } from 'api/GraphQL/util';
import { localeTolanguageCodeEnum } from 'container/ProductDetailContainer/utils';
import useAppStatus from 'hooks/useAppStatus';
import { ChannelMinimalResponseDTO } from 'generated';

const ProductInfo = ({
  id,
  category,
  externalReference: vendorProductId,
  small,
  description: descriptionString,
  translation,
  showDiscountPrice,
  hideVideos, hideChannels,
  hideHashtags,
  ...product
}: Product & {
  small?: boolean;
  showDiscountPrice?: boolean;
  hideVideos?: boolean;
  hideChannels?: boolean
  hideHashtags?: boolean
}) => {
  const supportedLanguages = useReduxSelector(selectSupportedLanguages)
  const { t } = useTranslation(['translation', 'product', 'video']);
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);
  const hashtags = pickHashtags(product as Product);

  const description = JSON.parse(translation?.description ?? descriptionString ?? '{"blocks":[]}')
  const { data: { pages: _videos = [] } = {}, error } = useGetProductVideos({ saleorProductIds: [id] })
  const videos = useMemo(() => _videos.map(({ data }) => data).flat(), [_videos])
  const channels = useMemo(() =>
    (videos.map((res => res.channels)).flat().filter(Boolean) as ChannelMinimalResponseDTO[])
      .reduce(
        (acc, current) => acc.find(({ id }) => id === current.id)
          ? acc
          : [...acc, current],
        [] as ChannelMinimalResponseDTO[]
      )
    , [videos])
  const { setAppStatus } = useAppStatus()
  useEffect(() => {
    if (error) {
      setAppStatus(error.message, 'error')
    }
  }, [error])
  return (
    <FlexBox
      className={classNames('product-info', small && 'product-info--small')}
      flexDirection="column"
    >
      <LocalisationTabs showTabPaneContent={isDesktop} >

        {
          supportedLanguages
            .map(localeTolanguageCodeEnum)
            .map(locale =>

              <TabPane
                key={id + locale}
                id={id}
                title={product.name === '' ? t('no_title') : product.name}
                subtitle={vendorProductId!}
                name={locale ?? ''}
                price={pickProductPrice(product)}
                discountPrice={pickProductPrice(product).discountPrice}
                noStock={pickProductStock(product as Product) === 0}
              >
                {pickProductShopUrl(product as Product) && (
                  <LabelText label={t('product:shop_url')}>
                    <p className="product-info__text product-info__text--ellipsis">
                      <a
                        className="link"
                        href={pickProductShopUrl(product as Product)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {pickProductShopUrl(product as Product)}
                      </a>
                    </p>
                  </LabelText>
                )}
                {description?.blocks && description?.blocks.length !== 0 && (
                  <Spacer marginTop={4}>
                    <LabelText
                      label={t('product:info')}
                      initiallyCollapsed
                      collapsible
                    >
                      <LabeledInfoText
                        staticContent={translation?.description?.blocks ?? description?.blocks} />
                    </LabelText>
                  </Spacer>
                )}
                {category && <Spacer marginTop={4}>
                  <LabelText label={t('product:categories.label')}>
                    <Spacer
                      key={category?.id}
                    >
                      <CategoryBranch category={category as Category} />
                    </Spacer>
                  </LabelText>
                </Spacer>}
                {
                  !hideHashtags && hashtags && hashtags.length > 0 && (
                    <Spacer marginTop={4}>
                      <LabelText label={t('product:hashtag.label')}>
                        {hashtags.map((hashtag, index) => (
                          <span className="product-info__category" key={hashtag}>
                            {`#${hashtag}${(hashtags?.length || 0) > 1 &&
                              index !== (hashtags?.length || 0) - 1
                              ? ', '
                              : ''
                              }`}
                          </span>
                        ))}
                      </LabelText>
                    </Spacer>
                  )
                }
              </TabPane>)}
      </LocalisationTabs>


      {
        !hideChannels && channels && channels.length !== 0 && (
          <Spacer marginTop={8} borderTop={1} paddingTop={4}>
            <LabelText label={t('product:channel')}>
              <FlexBox
                tag="ul"
                className="product-info__channels"
                marginTop={1}
                alignItems="flex-end"
              >
                {channels.map(({ name, id, availability }) => (
                  <li key={id} className="product-info__channel-item">
                    <Chip
                      color={
                        availability?.active
                          ? Color.confirm
                          : ColorName['black-20']
                      }
                    >
                      {name}
                    </Chip>
                  </li>
                ))}
              </FlexBox>
            </LabelText>
          </Spacer>
        )
      }

      {
        !hideVideos && videos && videos.length !== 0 && (
          <Spacer marginTop={8}>
            <ProductVideoList videos={videos} />
          </Spacer>
        )
      }
    </FlexBox >
  );
};
export default ProductInfo;
