import { HashtagResponseDTO, LocaleDTO, } from '../../generated';
import { Category, LanguageCodeEnum, Product, ProductBulkCreateInput, ProductBulkTranslateInput, ProductCreateInput, ProductInput, ProductType, ProductVariantBulkCreateInput, ProductVariantBulkTranslateInput, ProductVariantBulkUpdateInput, ProductVariantInput, TranslationInput } from 'generated/gql/gql';
import { pickHashtags, pickMeta, pickProductIsAvailable, pickProductName, pickProductPrice, pickProductStock } from 'api/GraphQL/util';



type ProductLocalisation = {
  currency?: string
  description?: { blocks: { type: string, data: { text: string, title?: string } }[] }
  discountPrice?: number
  name?: string
  price?: number
  shopUrl?: string
  stock?: number

} & LocaleDTO
export type ProductForm = {
  externalReference?: string
  hashtags?: HashtagResponseDTO[]
  localisations: (ProductLocalisation & LocaleDTO)[]
  isAvailable?: boolean
  category?: Category
  saleorChannelId: string
  saleorWarehouseId?: string
  productType: string
}
export const makeProductForm = ({ product, hashtags, supportedLanguages, saleorChannelId,
  saleorWarehouseId, productTypes }
  : {
    product?: Product,
    hashtags?: HashtagResponseDTO[],
    supportedLanguages?: LocaleDTO[],
    saleorChannelId?: string,
    saleorWarehouseId?: string,
    productTypes: ProductType[]

  }): ProductForm => {
  let _hashtags: HashtagResponseDTO[] = []
  try {
    const ht = pickHashtags(product)
    _hashtags = ht.filter(Boolean).map((name, id) => ({ name, id }))

  } catch (error) {
    console.log('error', error)
  }
  return ({

    externalReference: product?.externalReference ?? '',
    hashtags: _hashtags,
    localisations: supportedLanguages
      ? supportedLanguages?.map(locale => ({
        ...(locale ?? {}) as LocaleDTO,
        name: pickProductName(product) ?? '',
        ...pickProductPrice(product as Product),
        description: JSON.parse((product?.translation?.description ?? product?.description
          ?? '{ "blocks" : [] }'))?.blocks ?? [],
        shopUrl: pickMeta(product, 'shopUrl') || '',
        price: pickProductPrice(product as Product).amount,
        stock: pickProductStock(product as Product),

      })) : [],
    saleorChannelId: product?.channelListings?.[0]?.id ?? saleorChannelId,
    isAvailable: pickProductIsAvailable(product),//?.isAvailable ?? false,
    category: product?.category,
    saleorWarehouseId: saleorWarehouseId,
    productType: product?.productType?.id ?? productTypes[0]?.id
  }) as ProductForm

}
type ProductCreation = {
  updateInput: ProductInput
  translations: Partial<
    Record<
      LanguageCodeEnum,
      TranslationInput
    >
  >
  bulkTranslateInput: (_product: Product) => ProductBulkTranslateInput[]
  updateProductVariantInput: ProductVariantInput
  hashtags: HashtagResponseDTO[]
  bulkCreationInput: ProductBulkCreateInput
  bulkProductVariantUpdateInput: (_product: Product) => ProductVariantBulkUpdateInput[]
  bulkProductVariantTranslateInput: (_product: Product) => ProductVariantBulkTranslateInput[]
}
export const productFormToSaleorInput = (form: ProductForm,

): ProductCreation => {


  const updateInput: ProductInput = {
    category: form.category?.id,
    externalReference: form.externalReference,
    name: form.localisations[0].name,
    metadata: [
      {
        key: 'hashtags',
        value: (form.hashtags ?? []).map(({ name }) => name).join(',')
      },
      ...(form.localisations[0].shopUrl ? [{
        key: 'shopUrl',
        value: form.localisations[0].shopUrl
      }] : []),
    ],
    description: JSON.stringify({
      blocks: form.localisations[0].description
    }),
  }
  const translations: Partial<
    Record<
      LanguageCodeEnum,
      TranslationInput
    >
  > =
    form
      .localisations
      .reduce((acc, locale) => ({
        ...acc,
        [localeTolanguageCodeEnum(locale as LocaleDTO)]: {
          description: JSON.stringify({
            blocks: locale.description
          }),
          name: locale.name,
        }
      }), {})
  const bulkTranslateInput =
    (_product: Product): ProductBulkTranslateInput[] =>
      Object.entries(translations).map(([languageCode, field]) => ({
        id: _product?.id!,
        languageCode: languageCode as LanguageCodeEnum,
        translationFields: field
      }))
  const productVariantBulkCreateInput: ProductVariantBulkCreateInput = ({
    attributes: [],
    externalReference: form.externalReference,
    channelListings: form.localisations.map((field, index) => ({
      channelId: form.saleorChannelId,
      price: field.price,
    })),
    metadata: [
      {
        key: 'hashtags',
        value: (form?.hashtags ?? []).map(({ name }) => name).join(',')
      },
      ...(form.localisations[0].shopUrl ? [{
        key: 'shopUrl',
        value: form.localisations[0].shopUrl
      }] : [])
    ],
    stocks: form.localisations.map((field, index) => ({
      quantity: field.stock ?? 0,
      warehouse: form.saleorWarehouseId!
    })),
  })

  const updateProductVariantInput: ProductVariantInput = {
    attributes: [],
    externalReference: form.externalReference,
  }

  const bulkCreationInput: ProductBulkCreateInput = {
    productType: form.productType, //TODO : change this to a variable or a constant?
    externalReference: form.externalReference,
    category: form.category?.id,
    name: form.localisations[0].name,
    description: JSON.stringify({ blocks: form.localisations[0].description }),
    metadata: [
      {
        key: 'hashtags',
        value: (form.hashtags ?? []).map(({ name }) => name).join(',')
      },
      ...(form.localisations[0].shopUrl ? [{
        key: 'shopUrl',
        value: form.localisations[0].shopUrl
      }] : []),
    ],
    channelListings: [
      {
        channelId: form.saleorChannelId,
        isAvailableForPurchase: form.isAvailable,

      }
    ],
    variants: [productVariantBulkCreateInput]
  }
  const bulkProductVariantUpdateInput =
    (_product: Product): ProductVariantBulkUpdateInput[] =>
      _product.variants
        ? _product.variants.map((variant, i) => ({
          externalReference: form.externalReference,
          id: variant.id,
          name: form.localisations[i].name,
          channelListings: {
            update: variant.channelListings?.map(
              (listing, i) => ({
                channelListing: listing.id,
                price: form.localisations[i].price,

              })
            ) ?? [],
          },
          metadata: [
            {
              key: 'hashtags',
              value: (form?.hashtags ?? []).map(({ name }) => name).join(',')
            },
            ...(form.localisations[0].shopUrl ? [{
              key: 'shopUrl',
              value: form.localisations[0].shopUrl
            }] : []),
          ],
          stocks: {
            update: !variant.stocks?.length
              ? []
              : (variant.stocks ?? []).map((stock, i) => ({ stock: stock.id, quantity: form.localisations[i].stock ?? 0 })),
            create: !!variant.stocks?.length || form.localisations.length === 0
              ? []
              : form.localisations.map((stock, i) => ({ quantity: stock.stock ?? 0, warehouse: form.saleorWarehouseId! }))
          }
        }))
        : [({
          externalReference: form.externalReference,
          id: _product.defaultVariant?.id!,
          name: _product.name,
          channelListings: {
            update: _product
              .defaultVariant
              ?.channelListings
              ?.map(
                (listing, i) => ({
                  channelListing: listing.id,
                  price: form.localisations[i].price,
                })
              ) ?? [],
          },
          metadata: _product.metadata
        })
        ]
  const bulkProductVariantTranslateInput =
    (_product: Product): ProductVariantBulkTranslateInput[] =>
      form.localisations.map((locale, i) => ({
        externalReference: form.externalReference,
        id: _product?.variants?.[i]?.id!,
        languageCode: localeTolanguageCodeEnum(locale),
        translationFields: {
          name: locale.name,
        }
      }))
  return {
    updateInput,
    translations,
    bulkTranslateInput,
    updateProductVariantInput,
    hashtags: form.hashtags ?? [],
    bulkCreationInput,
    bulkProductVariantUpdateInput,
    bulkProductVariantTranslateInput
  }
}

export const localeTolanguageCodeEnum = ({ country, iso }: Pick<LocaleDTO, 'country' | 'iso'>): LanguageCodeEnum => {
  return createLanguageCodeEnum(iso, country)
}
export const createLanguageCodeEnum = (_iso: string, _country: string) => {
  const iso = _iso?.toLocaleUpperCase()
  const country = _country?.toLocaleUpperCase()
  if (iso && country) {
    return iso === country
      ? iso as LanguageCodeEnum
      : `${iso}_${country}` as LanguageCodeEnum

  } else if (iso && !country) {
    return iso as LanguageCodeEnum
  } else if (!iso && country) {
    return country as LanguageCodeEnum
  } else {
    return LanguageCodeEnum.En // default fallback
  }
}
export const localeStringToLanguageCodeEnum = (locale: string = ''): LanguageCodeEnum => {
  const [iso, country] = locale.toLocaleUpperCase().split('-')
  return createLanguageCodeEnum(iso, country)
}