import React from 'react'
import { Spacer } from 'components/Layout';
import { BREAKPOINTS } from 'global-constants';
import { useMediaQuery } from 'hooks/useMediaquery';
import useOpen from 'hooks/useOpen';
import { useTranslation } from 'react-i18next';
import CategoryBranch from '../../../components/CategoryBranch/CategoryBranch';
import './categories.scss';
import { Category } from 'generated/gql/gql';
import CategoryPickerModalDesktop from './components/desktop/CategoryPicker.modal';
import CategoryPickerModalMobile from './components/mobile/CategoryPicker.modal';
import { useCategoryQuery } from '../queries';

export type Props = {
    onSelectChange: (value?: Category) => void;
    value?: Category;
    emptyMessage?: string;
}
const SaleorCategories: React.FC<Props> = ({
    onSelectChange,
    value: _value,
    emptyMessage
}) => {
    const { data: { pages: [value] = [] } = {} } = useCategoryQuery(_value?.id);
    const { t } = useTranslation(['translation', 'product']);
    const { open, close, isOpen } = useOpen(false);
    const isDesktop = useMediaQuery(`(min-width:${BREAKPOINTS.m} )`);

    const modalProps = {
        open: isOpen,
        close,
        onSelect: onSelectChange,
        value: value?.category as Category
    }
    return <div className="categories">
        {value?.category &&
            <Spacer margin={[6, 0, 8]} key={value?.category?.id ?? _value?.id}>
                <CategoryBranch category={value?.category as Category} />
            </Spacer>
        }

        {Array.isArray(value?.category) && (
            <Spacer className="bg-secondary" padding={4} tag="p" marginBottom={6}>
                {t('product:categories.legacyHint')}
            </Spacer>
        )}

        {!value?.category && (
            <Spacer tag="p" className="categories__empty" margin={[2, 0, 4]}>
                {emptyMessage ?? t('product:categories.empty')}
            </Spacer>
        )}

        <button
            type="button"
            className="caption--primary categories__button"
            onClick={open}
        >
            {t('product:categories.button')}
        </button>
        {
            isDesktop
                ? <CategoryPickerModalDesktop {...modalProps} />
                : <CategoryPickerModalMobile {...modalProps} />
        }
    </div >
}
export default SaleorCategories