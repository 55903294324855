import ActionPanel from 'components/ActionPanel/ActionPanel';
import Button from 'components/Button/Button';
import ConfirmModal from 'components/ConfirmModal/ConfirmModal';
import Footer from 'components/Footer/Footer';
import GridItem from 'components/Grid/GridItem';
import HowTo from 'components/HowTo/HowTo';
import IconButton from 'components/IconButton/IconButton';
import LabelText from 'components/LabelText/LabelText';
import { Spacer } from 'components/Layout/Layout';
import List from 'components/List/List';
import ListItem from 'components/ListItem/ListItem';
import Loader from 'components/Loader/Loader';
import Modal from 'components/Modal/Modal';
import ProductInfo from 'components/ProductInfo/ProductInfo';
import Search from 'components/Search/Search';
import { HashtagAsyncField } from 'features/hashtags';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import {
  BatchLinkHashtagsErrorDto,
  HashtagResponseDTO,
  KeyValueDTO,
  KeyValueStoreService,
} from 'generated';
import { BREAKPOINTS } from 'global-constants';
import useAppStatus from 'hooks/useAppStatus';
import { useMediaQuery } from 'hooks/useMediaquery';
import useOpen from 'hooks/useOpen';
import placeholderImage from 'images/placeholder-product.svg';
import howToProduct from 'images/product-empty.png';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Color from 'types/Color';
import ErrorModal from './ErrorModal';
import { Product, } from 'generated/gql/gql';
import useProducts from './useProductsList';
import { pickProductIsAvailable, pickProductIsPublished, pickProductPrice, pickProductStock } from 'api/GraphQL/util';
import ToggleProductAvailability from 'features/product-control/ToggleProductAvailability';
import ToggleProductVisibility from 'features/product-control/ToggleProductVisibility';

type UpdateValues = {
  hashtags?: HashtagResponseDTO[];
};

const ProductContainer = () => {
  const { t } = useTranslation(['translation', 'product']);
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);
  const {
    isOpen: modalIsOpen,
    open: openModal,
    close: closeModal,
  } = useOpen(false);
  const {
    isOpen: panelIsOpen,
    open: openPanel,
    close: closePanel,
  } = useOpen(isDesktop);
  const [activeItem, setActiveItem] = useState<Product>();
  const [searchTerm, setSearchTerm] = useState('');
  const { products, data, status, refetch: get,
    isFetching, isLoading, isFetched, isInitialLoading,
    isFetchedAfterMount,
    isRefetching, updateProduct,
    updating, activating,
    error,
    hasNextPage,
    deleteProduct,
    fetchNextPage: fetchNext,
    updateProductAvailability,
    productRecord,
    ...rest


  } = useProducts
      ({ search: searchTerm, setActiveItem, activeItem });


  const showHowTo = (data?.pages?.length ?? 0) === 0 && status === 'success';
  const { setAppStatus } = useAppStatus();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const selectAll = selectedIds.length === products?.length;
  const setSelectAll = () => {
    if (selectAll) {
      setSelectedIds([])
    } else {
      setSelectedIds((products ?? []).map(({ id }) => id) || [])
    }
  }

  const [openEditModal, setOpenEditModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [mutationErrors, setMutationErrors] =
    useState<BatchLinkHashtagsErrorDto[]>();

  // TODO take from kvstore Redux
  const [showDiscount, setShowDiscount] = useState(false);


  useEffect(() => {
    if (!activeItem) {
      closePanel();
    }
  }, [activeItem]);


  const getShowDiscount = async (): Promise<boolean> => {
    try {
      const keyValueDto = await KeyValueStoreService.getKeyValueStoreItem(
        KeyValueDTO.key.ENABLE_DISCOUNT_PRICES,
      );

      return keyValueDto.value === 'true';
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    const discount = async () => {
      const showDiscount = await getShowDiscount();
      setShowDiscount(showDiscount);
    };
    discount();
  }, []);

  const handleDelete = () => {
    if (activeItem) {
      deleteProduct({ id: activeItem.id }, {
        onSuccess: (res) => {
          closeModal();
          setAppStatus(
            t('product:delete.success', { product: res.productDelete?.product?.externalReference }),
            'success',
          );
          setActiveItem(products?.[0])
        },
        onError: (error) => {
          closeModal();
          setAppStatus(
            t('product:delete.error', {
              product: activeItem.externalReference,
            }),
            'error',
            true,
          );
        },
      });
    }
  };

  const handleOpenDetails = (item: Product) => {
    setActiveItem(item);
    openPanel();
  };

  const handleClose = () => {
    closePanel();
    setTimeout(() => {
      setActiveItem(undefined);
    }, 600);
  };

  const handleLoadMore = () => fetchNext()


  const handleSearchTermChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchTerm(event.currentTarget.value);
  };

  const handleSelectAll = (checked: boolean) => {
    setSelectAll()
  };

  const handleSelectChange = (id: string) => {
    setSelectedIds((prev) => [
      ...(prev.includes(id)
        ? prev.filter((i) => i !== id)
        : [...prev, id]),
    ]);

  };

  const handleEdit = (
    values: UpdateValues,
    formikHelpers: FormikHelpers<UpdateValues>,
  ) => {
    Promise.all(selectedIds.map(async (id) => {
      await updateProduct({
        id: id,
        input: {
          metadata:
            [{
              key: 'hashtags',
              value: JSON.stringify(values.hashtags)
            }
            ]
        },
      })
    })).then(() => {
      formikHelpers.resetForm();
      setOpenEditModal(false);
    })
  }

  return !showHowTo ? (
    isLoading && !rest.totalCount ? (
      <GridItem className="page__content" column="1/4">
        <Loader />
      </GridItem>
    ) : (
      <>
        <GridItem className="page__content" templateArea="main">
          <div className="page__controls page__controls--list">
            <Search
              searchTerm={searchTerm}
              onChange={handleSearchTermChange}
              placeholder={t('product:searchPlaceholder')}
            />

            <Button link="/products/new" text={t('add')} icon="add" />
          </div>

          <div className="page__controls-list page__list">
            <IconButton
              icon="edit"
              onClick={() => setOpenEditModal(true)}
              disabled={selectedIds.length === 0}
              wrapperClassName="page__controls-delete"
              appearance={selectedIds.length === 0 ? 'ghost' : 'filled'}
              big
            />
            <List
              count={{
                itemsVisible: products?.length,
                total: rest.totalCount ?? 0,
              }}
              handleLoadMore={handleLoadMore}
              loading={isLoading}
              error={error ?? undefined}
              showLoadMore={hasNextPage}
              className="page__list"
              selectAll={{
                checked: selectAll,
                onSelectAll: handleSelectAll,
              }}
            >
              {products &&
                products?.map((item, index) => (
                  <ListItem
                    deactivated={!pickProductIsAvailable(item)}
                    active={item.id === activeItem?.id}
                    key={item.id}
                    title={item.name || t('no_title')}
                    subtitle={
                      item?.externalReference || t('no_title')}
                    price={
                      pickProductPrice(item)
                    }
                    discountPrice={
                      pickProductPrice(item).discountPrice

                    }
                    image={item?.thumbnail?.url}
                    onClick={() => handleOpenDetails(item as Product)}
                    id={`${item.id}`}
                    placeholder={placeholderImage}
                    selectable={{
                      selected: selectedIds?.includes(item.id) || false,
                      onSelectChange: () => handleSelectChange(item.id),
                    }}
                    noStock={pickProductStock(item) === 0}
                    isPrivate={!pickProductIsPublished(item)}
                  />
                ))}
            </List>
          </div>
          {rest?.totalCount && rest?.totalCount > 15 && (
            <div className="page__controls ">
              <Button link="/products/new" text={t('add')} icon="add" />
            </div>
          )}
        </GridItem>
        <GridItem templateArea="sidebar">
          <ActionPanel onClose={handleClose} open={panelIsOpen}>
            {activeItem && (
              <>
                <div className="page__controls">
                  <div className="page__controls-left">
                    <ToggleProductAvailability product={activeItem} refetch={get} refetching={isFetching || isLoading} />

                    <ToggleProductVisibility product={activeItem} refetch={get} refetching={isFetching || isLoading}
                    />
                  </div>
                  <div className="page__controls-right">
                    <IconButton
                      tooltip={t('product:editDelete')}
                      onClick={openModal}
                      type="button"
                      icon="delete"
                      big={true}
                    />

                    <ConfirmModal
                      isOpen={modalIsOpen}
                      headline={t('product:editDelete')}
                      text={t('product:really_delete', {
                        title: activeItem.externalReference
                      })}
                      onCancelClick={closeModal}
                      onConfirmClick={handleDelete}
                      confirmText={t('delete')}
                    />
                    <IconButton
                      tooltip={t('product:edit.label')}
                      icon="edit"
                      link={`/products/${activeItem.id}`}
                      appearance="filled"
                      big
                    />
                  </div>
                </div>
                <ProductInfo
                  key={activeItem.externalReference}
                  {...activeItem}
                  showDiscountPrice={showDiscount}
                />
              </>
            )}
          </ActionPanel>
        </GridItem>
        <GridItem templateArea="footer">
          <Footer />
        </GridItem>

        <Modal
          isOpen={openEditModal}
          headline={t('product:edit.headline', { count: selectedIds.length })}
          onClose={() => setOpenEditModal(false)}
          variant="wide"
        >
          <div className="modal__text">
            <p>{t('product:edit.desc')}</p>
          </div>
          <Formik
            initialValues={{ hashtags: [] } as UpdateValues}
            onSubmit={handleEdit}
          >
            {(formik: FormikProps<UpdateValues>) => (
              <Form>
                <Spacer marginTop={8}>
                  <LabelText label={t('product:hashtag.label')}>
                    <Spacer
                      marginBottom={2}
                      tag="p"
                      className="page__form-desc"
                    >
                      {t('product:edit.hashtags')}
                    </Spacer>
                    <HashtagAsyncField />
                  </LabelText>
                </Spacer>

                {formik.status && (
                  <Spacer className="c-warning modal__text" marginTop={4}>
                    <p>{formik.status}</p>
                  </Spacer>
                )}

                <div className="modal__controls">
                  <Button
                    text={t('cancel')}
                    type="button"
                    onClick={() => setOpenEditModal(false)}
                    color={Color.primary}
                    appearance="ghost"
                  />

                  <Button
                    text={t('video:batchVisibility.confirm')}
                    type="button"
                    onClick={() => formik.submitForm()}
                    color={Color.primary}
                    loading={updating}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </Modal>
        <ErrorModal
          openErrorModal={openErrorModal}
          setOpenErrorModal={setOpenErrorModal}
          productRecord={productRecord}
          errors={mutationErrors}
        />
      </>
    )
  ) : (
    <>
      <GridItem column="1 / 4">
        <HowTo
          button={{ link: '/products/new', text: t('product:addProduct') }}
          text={t('product:empty')}
          image={howToProduct}
        />
      </GridItem>
      <GridItem column="1 / 4">
        <Footer />
      </GridItem>
    </>
  );
};

export default ProductContainer;
