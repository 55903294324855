import React from 'react';
import i18n from 'i18next';
import classNames from 'classnames';
import './price.scss';
import Icon from 'components/Icon/Icon';
import Tooltip from 'components/Tooltip/Tooltip';

export interface Props {
  amount?: number;
  currency?: string;
  className?: string;
  crossedOut?: boolean;
  discounted?: boolean;
  noStock?: boolean
}

export const formatPrice = (amount: number, currency: string = 'USD') => {
  const lang = i18n.language;
  const priceFormatted = new Intl.NumberFormat(lang, {
    style: 'currency',
    currency: currency,
  }).format(amount);

  return priceFormatted;
};

const Price = ({
  amount,
  currency = 'EUR',
  className,
  crossedOut,
  discounted,
  noStock
}: Props) => {
  const priceFormatted = formatPrice(amount ?? 0, currency);
  return (
    <span className='price__wrapper'>
      <div
        className={classNames(
          'price',
          crossedOut && 'price--crossed',
          discounted && 'price--discount',
          className,
        )}
      >
        <span className="price__text">{priceFormatted}</span>
      </div>

      {noStock && <Tooltip content='no stock!'>
        <span className="price__wrapper--warning" ><Icon className='price__icon--nostock' icon={'help'} /></span>
      </Tooltip>}


    </span>
  );
};
export default Price;
